<template>
	<div class="columns is-centered">
		<div class="column is-3">
			<form class="form-horizontal" autocomplete="off" >
				<fieldset>
					<!-- Form Name -->
					<legend class="has-text-right">Register</legend>

					<!-- Text input-->
					<div class="field">
						<label class="label" for="textinput-n">Emri</label>
						<div class="control">
							<input id="textinput-n" v-model="new_user.name"
								name="textinput-n" type="text" placeholder="Emri"
								class="input ">
							<!-- <p class="help">Your email here</p> -->
						</div>
					</div>
					<!-- Text input-->
					<div class="field">
						<label class="label" for="textinput-0">Email</label>
						<div class="control">
							<input id="textinput-0" v-model="new_user.email"
								name="textinput-0" type="email" placeholder="Email"
								class="input " autocomplete="new-password">
							<!-- <p class="help">Your email here</p> -->
						</div>
					</div>

					<!-- Password input-->
					<div class="field">
						<label class="label" for="passwordinput-0">Password</label>
						<div class="control">
							<input id="passwordinput-0" v-model="new_user.password"
								name="passwordinput-0"
								type="password"
								placeholder="Password" class="input"
								readonly
								onfocus="this.removeAttribute('readonly');">
							<!-- <p class="help">Specify your password</p> -->
						</div>
					</div>

					<!-- Password input-->
					<div class="field">
						<label class="label" for="passwordinput-1">Perserit Password</label>
						<div class="control">
							<input id="passwordinput-1"
								v-model="new_user.password_confirmation" name="passwordinput-1"
								type="password" placeholder="Perserit Password" class="input" 
								readonly onfocus="this.removeAttribute('readonly');">
							<!-- <p class="help">Repeat your password here</p> -->
						</div>
					</div>
					<div class="field border-top">
						<router-link to="/login" class="button is-small is-pulled-left">
							<i class="fa-solid fa-chevron-left mr-2 has-text-grey"></i>
							Login
						</router-link>
						<button 
							class="button is-small is-pulled-right is-info is-outlined" 
							@click.prevent="register">Regjistrohu</button>
					</div>
				</fieldset>
			</form></div>
	</div>
</template>
<script charset="utf-8">
import Api from '@/services/Api.js'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
var CryptoJS = require("crypto-js");
export default {
	data(){
		return {
			new_user: {
				name: '',
				firstname: '-',
				lastname: '-',
				email: '',
				password: '',
				password_confirmation: '',
			},
			user_store: storeToRefs(useUserStore())
		}
	}, 
	created(){
		this.$document.title = "Register"
	},
	methods: {
		register(){
			Api().post('user/register', this.new_user)
				.then(r => {
					localStorage.user = JSON.stringify(r.data.user)
					this.user_store.user = r.data.user
					localStorage.token = r.data.token

					// Save permissions to localstorage
					var a = CryptoJS.AES.encrypt(JSON.stringify(r.data.user_permissions), localStorage.token).toString();
					localStorage.permissions = a

					this.$router.push({ path: '/home'})
					this.user_store.is_loggedin = true
				})
				// .catch(( error ) => {
				// 	if(error.response){
				// 		var err = error.response.data
				// 		this.$toast.warning(err.message)
				// 		Object.keys(err['errors']).map((e, i) => {
				// 			this.$toast.error((i+1) + '. ' + err.errors[e])
				// 		})
				// 	}
				// })
		}
	}
}	
</script>
